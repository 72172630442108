<template>
  <div
    style="height: 100%; width: 100%; display: flex; justify-content: center; align-items: center"
  >
    <v-card
      class="d-flex flex-column align-center text-center register-disabled__container ma-0 ma-sm-5 pa-0 pa-sm-6"
      :outlined="$vuetify.breakpoint.smAndUp"
      :flat="$vuetify.breakpoint.xsOnly"
    >
      <logo-image height="150px" />
      <div class="font-weight-bold mb-2 mt-3">
        We are not accepting new virtual appointments at this time.
      </div>
      <div class="mb-5">
        Please proceed to the nearest location to schedule an in-person appointment.
      </div>
      <div class="d-flex justify-space-around mb-5 align-center flex-column mt-8">
        <v-btn
          color="primary"
          class="mb-2"
          href="https://www.sentryhealth.ca/locations"
          target="_blank"
          >View locations</v-btn
        >
        <div class="mobile-call__button pa-2 d-flex">
          <v-icon>mdi-phone</v-icon>
          <div class="mr-1">Contact us:</div>

          <a href="tel:705-328-2019">705-328-2019</a>
        </div>
      </div>
    </v-card>
  </div>
</template>
<style lang="scss">
.mobile-call__button {
  text-align: center;
}
@media all and (max-width: 600px) {
  .register-disabled__container {
    max-width: calc(100% - 40px);
  }
}
@media all and (min-width: 600px) {
  .register-disabled__container {
    max-width: 600px;
    height: 500px;
  }
}
</style>
<script>
export default {
  components: {
    LogoImage: () => import('../components/LogoImage.vue'),
  },
  name: 'RegisterDisabled',
  data: () => ({}),
};
</script>
